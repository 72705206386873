.active {
    color: orange;
    text-decoration: none;
}

.pending {
    color: gray;
    text-decoration: none;
}

.not_active {
    color: inherit;
    text-decoration: none;
}
